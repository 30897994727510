import React from "react";
import Modal from "./Modal";
import Button from "../global/Button";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { keyjaniApi, tokenConfig } from "../../api";
import Spinner from "../global/Spinner";
import { WorkspaceType } from "../../types";
import { useAlert } from "../../hooks/useAlert";
import { ResponseStatus, Screens } from "../../types/enums";

type Props = {
  isOpen: boolean;
  Close: () => void;
  workspace?: WorkspaceType;
  item: any;
};

export default function DeleteInvitation({
  isOpen,
  Close,
  workspace,
  item,
}: Props) {
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: () =>
      keyjaniApi.delete(
        `/workspaces/${workspace?.slug}/invitation/${item?.pkid}`,
        tokenConfig()
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["invitations"] });
      showAlert({
        message: "Workspace Invitation deleted successfully",
        type: ResponseStatus.SUCCESS,
        component: Screens.workspace,
      });
      Close();
    },
    onError: (error: any) => {
      if (error.response && error.response.data) {
        Close();
        showAlert({
          error: error.response,
          type: ResponseStatus.FAILED,
          component: Screens.workspace,
        });
      }
    },
  });

  const headContent = (
    <div className="flex flex-row items-center justify-between">
      <div>
        <p className="text-[16px] font-semibold">Delete Invitation</p>
      </div>
      <div
        className="bg-itemhover p-1.5 rounded-md cursor-pointer"
        onClick={Close}
      >
        <XMarkIcon className="w-5 h-5" />
      </div>
    </div>
  );
  const bodyContent = (
    <div className="w-full">
      <div className="w-full flex flex-col space-y-4">
        <div className="w-5/6 flex flex-col space-y-4">
          <p className="text-[14px]">
            Are you sure you want to delete the invitation for{" "}
            <span className="text-primary_color">{item?.received_by}</span>?
          </p>
        </div>
      </div>
    </div>
  );

  const footerContent = (
    <div className="w-full flex items-center justify-end space-x-3">
      <div>
        <Button
          small
          text="Cancel"
          onClick={Close}
          buttonStyle="h-10 w-20 border border-itemactive font-bold bg-gradient-to-r rounded-md hover:bg-itemhover"
        />
      </div>
      <div>
        <Button
          small
          text={
            mutation.isPending ? <Spinner styles="h-4 w-4 border-2" /> : "Yes"
          }
          onClick={() => {
            if (workspace) {
              mutation.mutate();
            }
          }}
          buttonStyle="h-10 w-20 font-bold  bg-gradient-to-r rounded-md text-white bg-findit_red"
        />
      </div>
    </div>
  );
  return (
    <Modal
      open={isOpen}
      onClose={Close}
      body={bodyContent}
      actions={footerContent}
      title={headContent}
    />
  );
}
