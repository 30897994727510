import React, { useState } from "react";
import Modal from "./Modal";
import Button from "../global/Button";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { keyjaniApi, tokenConfig } from "../../api";
import Spinner from "../global/Spinner";
import CheckBox from "../global/CheckBox";
import { WorkspaceType } from "../../types";
import { useAlert } from "../../hooks/useAlert";
import { ResponseStatus, Screens } from "../../types/enums";

type Props = {
  isOpen: boolean;
  Close: () => void;
  workspace?: WorkspaceType;
  item: any;
};

export default function UpdateModal({ isOpen, Close, workspace, item }: Props) {
  const queryClient = useQueryClient();
  const [sortBy, setSortBy] = useState<string>("");
  const handleSortChange = (value: string) => {
    setSortBy(value);
  };
  const { showAlert } = useAlert();

  const mutation = useMutation({
    mutationFn: () =>
      keyjaniApi.patch(
        `/workspaces/${workspace?.slug}/members/${item?.pkid}`,
        {
          role: sortBy,
        },
        tokenConfig()
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["members"] });
      showAlert({
        message: "Workspace member updated successfully",
        type: ResponseStatus.SUCCESS,
        component: Screens.workspace,
      });
      Close();
    },
    onError: (error: any) => {
      if (error.response && error.response.data) {
        const responseData = error.response.data;
        Close();
        showAlert({
          error: responseData,
          type: ResponseStatus.FAILED,
          component: Screens.workspace,
        });
      }
    },
  });

  const headContent = (
    <div className="flex flex-row items-center justify-between">
      <div>
        <p className="text-[16px] font-semibold">Assign role</p>
      </div>
      <div
        className="bg-itemhover p-1.5 rounded-md cursor-pointer"
        onClick={Close}
      >
        <XMarkIcon className="w-5 h-5" />
      </div>
    </div>
  );
  const bodyContent = (
    <div className="w-full">
      <div className="flex flex-col space-y-5">
        <p className="text-[0.875rem] font-medium">Role Levels*</p>
        <CheckBox
          checked={sortBy === "Owner"}
          disabled
          onChange={() => handleSortChange("Owner")}
          right={
            <div className="flex flex-col">
              <label className="text-[0.855rem] font-medium">
                Workspace Owner
              </label>
              <p className="text-[0.855rem]  text-grey_black">
                Full access to team dashboard (list of all workspace, tenant
                creation restriction, team members management). Can access
                specific workspace of which they are a member.
              </p>
            </div>
          }
          type="radio"
        />
        <CheckBox
          checked={sortBy === "Admin"}
          onChange={() => handleSortChange("Admin")}
          right={
            <div className="flex flex-col">
              <label className="text-[0.855rem] font-medium">Admin</label>
              <p className="text-[0.855rem]  text-grey_black">
                Can view, create, edit and access specific workspace of which
                they are a member.
              </p>
            </div>
          }
          type="radio"
        />
        <CheckBox
          checked={sortBy === "Editor"}
          onChange={() => handleSortChange("Editor")}
          right={
            <div className="flex flex-col">
              <label className="text-[0.855rem] font-medium">Editor</label>
              <p className="text-[0.855rem]  text-grey_black">
                Can view and access specific workspaces of which they are a
                member.
              </p>
            </div>
          }
          type="radio"
        />
      </div>
    </div>
  );

  const footerContent = (
    <div className="w-full flex items-center justify-end space-x-3">
      <div>
        <Button
          small
          text="Cancel"
          onClick={Close}
          buttonStyle="h-10 w-20 border border-itemactive font-bold bg-gradient-to-r rounded-md hover:bg-itemhover"
        />
      </div>
      <div>
        <Button
          small
          text={
            mutation.isPending ? <Spinner styles="h-4 w-4 border-2" /> : "Yes"
          }
          onClick={() => {
            if (workspace) {
              mutation.mutate();
            }
          }}
          buttonStyle="h-10 w-20 font-bold  bg-gradient-to-r rounded-md text-white bg-findit_red"
        />
      </div>
    </div>
  );
  return (
    <Modal
      open={isOpen}
      onClose={Close}
      body={bodyContent}
      actions={footerContent}
      title={headContent}
    />
  );
}
