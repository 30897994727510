import React from "react";

type Props = {
  label?: string;
  type?: string;
  name?: string;
  placeholder?: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  iconRight?: React.ReactNode;
  iconLeft?: React.ReactNode;
  styles?: string;
  disabled?: boolean;
  error?: string | null | boolean;
  onFocus?: VoidFunction;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

export default function Input({
  label,
  type,
  name,
  placeholder,
  value,
  onChange,
  iconRight,
  iconLeft,
  styles,
  disabled,
  error,
  onFocus,
  onKeyDown,
}: Props) {
  const [focus, setFocus] = React.useState(false);

  const handleFocus = () => {
    if (onFocus) {
      onFocus();
    } else {
      setFocus(true);
    }
  };

  const handleBlur = () => {
    setFocus(false);
  };

  return (
    <div className="flex flex-col">
      <div
        className={`w-full flex items-center relative border-stroke ${styles} ${
          error
            ? "border-findit_red"
            : `${
                focus
                  ? "outline-none border-primary_color ring-primary_color "
                  : "border-stroke"
              }`
        } `}
      >
        {iconLeft && iconLeft}
        <input
          disabled={disabled}
          placeholder={placeholder}
          type={type}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onFocus={handleFocus}
          onBlur={handleBlur}
          name={name}
          className={`peer w-full text-[13px] px-4 py-3 pt-6 font-light bg-white rounded-xl outline-none transition disabled:opacity-70 disabled:cursor-not-allowed pl-4 `}
        />
        <label
          className={`absolute text-[13px] duration-150 transform -translate-y-3 top-5 z-10 origin-[0] left-4 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4`}
        >
          {label}
        </label>
        {iconRight && iconRight}
      </div>
      {error && <p className="text-findit_red text-xs">{error}</p>}
    </div>
  );
}
