import React from "react";
import { Button, SearchInput, Spinner } from "..";
import {
  EllipsisVerticalIcon,
  MagnifyingGlassIcon,
  PlusIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import {
  Menu,
  Table,
  Group,
  Text,
  ActionIcon,
  Anchor,
  LoadingOverlay,
} from "@mantine/core";
import { tenantColumns } from "../../utils/data";
import useTenant from "../../hooks/useTenant";
import { TenantTableProps } from "../../types";
import { useLoadingData } from "../../hooks/useLoadingData";

export default function TenantTable({ data, isLoading }: TenantTableProps) {
  const { toggleTenantModal, getCurrentTenant } = useTenant();
  const { sortedData, visible, search, handleSearchChange, handleReset } =
    useLoadingData(data, isLoading);

  const rows = sortedData?.map((item, index) => (
    <Table.Tr key={item?.id || item?.email || `tenant-${index}`}>
      <Table.Td>
        <Text fz="sm" fw={500}>
          {item?.first_name} {item?.last_name}
        </Text>
      </Table.Td>

      <Table.Td>
        <Anchor component="button" size="sm">
          {item?.email}
        </Anchor>
      </Table.Td>
      <Table.Td>
        <Anchor component="button" size="sm">
          {item?.phone_number}
        </Anchor>
      </Table.Td>
      <Table.Td>
        <Group gap={0} justify="flex-start">
          <Menu
            transitionProps={{ transition: "pop" }}
            withArrow
            position="bottom-end"
            withinPortal
          >
            <Menu.Target>
              <ActionIcon variant="subtle" color="gray">
                <EllipsisVerticalIcon className="h-5 w-5" />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown className="flex w-62.5 bg-back2 bg-cover bg-no-repeat min-w-[16px] min-h-[16px] cursor-pointer flex-col transform transition-all duration-[198ms] backdrop-blur-xl rounded-lg border border-stroke bg-white/90 shadow-default">
              <Menu.Item
                onClick={() => {
                  getCurrentTenant(item);
                }}
                leftSection={<TrashIcon className="h-4 w-4 text-red-400" />}
                className="hover:bg-background_color_second flex items-center space-x-3  px-3 py-1 rounded-md text-sm font-medium duration-300 ease-in-out lg:text-base"
              >
                <p className="text-red-400 text-[13px]">Delete Tenant</p>
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <div className="flex flex-col space-y-3">
      <div className="flex items-center justify-between space-x-3">
        <div>
          <h2 className="text-[20px] font-semibold text-primary_color dark:text-white">
            Tenants
          </h2>
        </div>
        <div className="flex items-center space-x-4">
          <SearchInput
            styles="border border-stroke bg-white rounded-3xl focus:border-blue-500"
            placeholder="Search in tenants..."
            onChange={handleSearchChange}
            name="search"
            icon={<MagnifyingGlassIcon className="w-4 h-4 text-gray_color" />}
            value={search}
            iconRight={
              search?.length > 0 && (
                <div
                  className="h-5 w-5 flex items-center rounded-full justify-center hover:bg-stroke cursor-pointer"
                  onClick={handleReset}
                >
                  <XMarkIcon className="w-3 h-3 text-gray_color" />
                </div>
              )
            }
          />
          <div>
            <Button
              text="Add a Tenant"
              leftIcon={<PlusIcon className="h-4 w-4 text-white" />}
              onClick={() => toggleTenantModal(true)}
              buttonStyle="px-4 py-2 text-white cursor-pointer rounded-md bg-primary_color flex items-center justify-center duration-700 ease-in-out hover:bg-primary_color/80"
            />
          </div>
        </div>
      </div>
      <Table.ScrollContainer
        minWidth={700}
        className="overflow-hidden relative border border-stroke rounded-md shadow-lg px-4 pt-4 pb-0"
      >
        <LoadingOverlay
          visible={visible}
          loaderProps={{
            children: <Spinner styles="h-4 w-4 border-2 text-primary_color" />,
          }}
        />
        <Table verticalSpacing="md">
          <Table.Thead>
            <Table.Tr>
              {tenantColumns.map((item) => (
                <Table.Th key={item?.id}>{item?.label}</Table.Th>
              ))}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </Table.ScrollContainer>
      <div>
        <p className="text-[14.5px] sm:text-[15px] font-medium">
          {sortedData.length === 0
            ? "No tenants"
            : sortedData.length === 1
            ? "1 tenant"
            : `${sortedData.length} tenants`}
        </p>
      </div>
    </div>
  );
}
