import React from "react";
type Props = {
  label?: string;
  type?: string;
  name?: string;
  placeholder?: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  iconRight?: React.ReactNode;
  iconLeft?: React.ReactNode;
  styles?: string;
  disabled?: boolean;
  error?: string | null | boolean;
  onFocus?: VoidFunction;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

export default function WorkspaceInput({
  label,
  type,
  name,
  placeholder,
  value,
  onChange,
  iconRight,
  iconLeft,
  styles,
  disabled,
  error,
  onFocus,
  onKeyDown,
}: Props) {
  const [focus, setFocus] = React.useState(false);

  const handleFocus = () => {
    if (onFocus) {
      onFocus();
    } else {
      setFocus(true);
    }
  };

  const handleBlur = () => {
    setFocus(false);
  };
  return (
    <div>
      <input
        type={type}
        name={name}
        onKeyDown={onKeyDown}
        value={value}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className={`${styles} h-full outline-none rounded border text-[13px]  text-white text-sm px-3 ${
          focus
            ? " border-stroke ring-stroke"
            : "border-primary_color ring-primary_color"
        }  block w-full`}
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChange}
      />
    </div>
  );
}
