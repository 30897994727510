import React from "react";

type Props = {
  name?: string;
  type: any;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean | undefined;
  right?: React.ReactElement;
  left?: React.ReactElement;
  value?: string;
  disabled?: boolean;
};

export default function CheckBox({
  name,
  type,
  value,
  disabled,
  onChange,
  checked,
  right,
  left,
}: Props) {
  return (
    <div className="flex items-center space-x-3">
      {left && left}
      <input
        type={type}
        value={value}
        onChange={onChange}
        name={name}
        checked={checked}
        className="w-4 h-4 border border-stroke1 rounded cursor-pointer focus:ring-3 focus:ring-blue-300"
        disabled={disabled}
      />

      {right && right}
    </div>
  );
}
