import React from "react";

export const Divider = () => {
  return (
    <div className="flex items-center w-full justify-center space-x-3">
      <hr className="border-stroke flex-1" />
      <p className="text-stroke text-sm text-center mb-1">Or continue with</p>
      <hr className="border-stroke flex-1" />
    </div>
  );
};

export const Divide = () => {
  return (
    <div className="w-full ">
      <hr className="border-stroke flex-1 " />
    </div>
  );
};
