import React from "react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import useCustomQuery from "../../hooks/useCustomQuery";
import Spinner from "./Spinner";

export type STATUSProps = {
  status: string;
};

export const STATUSES: STATUSProps[] = [
  { status: "Pending" },
  { status: "In Progress" },
  { status: "Done" },
];

type Props = {
  selected?: any;
  handleSelect: (value: any) => void;
  label?: string;
  workspace: string;
};

export default function Select({
  label,
  selected,
  handleSelect,
  workspace,
}: Props) {
  const [menuDown, setMenuDown] = React.useState(false);
  const queryMembers = useCustomQuery(
    "members",
    `/workspaces/${workspace}/members/`,
    !!workspace
  );

  const isSelected = (type: any) => selected && selected?.pkid === type.pkid;

  return (
    <div className="relative ">
      <div>
        <label className="block mb-2 text-[13px] text-gray-900">{label}</label>
      </div>
      <div
        onClick={() => setMenuDown((prevState) => !prevState)}
        className="bg-white flex items-center justify-between border border-stroke px-3 py-3.5 rounded-md"
      >
        <p className="text-[13px]">
          {selected ? selected.name : "workspace member"}
          {selected ? selected?.full_name : "workspace member"}
        </p>
        <ChevronDownIcon className={`w-4 h-4 ${menuDown && "rotate-180"}`} />
      </div>
      <div
        className={`border border-stroke  w-full rounded-md bg-white shadow-default  ${
          menuDown ? "absolute z-999" : "hidden"
        }`}
      >
        {queryMembers.isLoading ? (
          <div className="flex items-center justify-center h-full w-full">
            <Spinner styles="h-4 w-4 border-2 text-primary_color" />
          </div>
        ) : (
          queryMembers.data?.data?.map((member: any) => (
            <div
              key={member.pkid}
              onClick={() => {
                handleSelect(member);
                setMenuDown(false);
              }}
              className={`flex items-center cursor-pointer justify-between py-4 text-[13px] ${
                isSelected(member) ? "bg-[#a8b3cf1f] rounded-md" : ""
              } border-b border-b-stroke text-meta-8 relative cursor-default select-none px-4`}
            >
              <p className="text-[13px]">{member?.name}</p>
              {isSelected(member) && (
                <CheckIcon className="w-4 h-4 text-primary_color" />
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
}
