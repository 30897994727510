import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UnitType, UnitTypes } from "../types";
import { RootState } from "../redux/store";
import {
  DeleteUnit,
  setCurrentUnit,
  setUnit,
  setUnitModal,
  setUnitModalDelete,
  setUnitModalUpdate,
  setUnits,
  setUnitTenantModal,
  setUnitToBeEdited,
  UpdateUnit,
} from "../redux/features/units/unitSlice";

const initialUnitState: UnitTypes = {
  number: "",
};

export default function useUnit() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [values, setValues] = React.useState<UnitTypes>(initialUnitState);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleClearForm = () => {
    setValues(initialUnitState);
  };

  const state = useSelector((state: RootState) => state.unit);

  const getCurrentUnit = useCallback(
    (unit: UnitType) => {
      dispatch(setCurrentUnit(unit));
    },
    [dispatch]
  );

  const getUnits = useCallback(
    (units: UnitType[]) => {
      dispatch(setUnits(units));
    },
    [dispatch]
  );

  const getUnit = useCallback(
    (unit: UnitType) => {
      dispatch(setUnit(unit));
    },
    [dispatch]
  );

  const updateUnit = useCallback(
    (unit: UnitType) => {
      dispatch(UpdateUnit(unit));
    },
    [dispatch]
  );

  const deleteUnit = useCallback(
    (unit: UnitType) => {
      dispatch(DeleteUnit(unit));
    },
    [dispatch]
  );

  const getUnitToBeEdited = useCallback(
    (unit: string) => {
      dispatch(setUnitToBeEdited(unit));
    },
    [dispatch]
  );

  const toggleUnitModal = useCallback(
    (toggle: boolean) => {
      dispatch(setUnitModal(toggle));
    },
    [dispatch]
  );
  const toggleUnitModalUpdate = useCallback(
    (toggle: boolean) => {
      dispatch(setUnitModalUpdate(toggle));
    },
    [dispatch]
  );
  const toggleUnitModalDelete = useCallback(
    (toggle: boolean) => {
      dispatch(setUnitModalDelete(toggle));
    },
    [dispatch]
  );

  const toggleUnitTenantModal = useCallback(
    (toggle: boolean) => {
      dispatch(setUnitTenantModal(toggle));
    },
    [dispatch]
  );

  return {
    values,
    ...state,
    navigate,
    dispatch,
    setValues,
    handleClearForm,
    getUnit,
    getUnits,
    deleteUnit,
    updateUnit,
    getCurrentUnit,
    toggleUnitModal,
    getUnitToBeEdited,
    handleInputChange,
    toggleUnitModalDelete,
    toggleUnitModalUpdate,
    toggleUnitTenantModal,
  };
}
