export const getUsernameInitials = (full_name: any) => {
  if (full_name) return full_name?.charAt(0).toUpperCase();
};

export const getTwoInitials = (text: any) => {
  if (!text) return "";

  const initialsArray = text
    .split(" ")
    .slice(0, 2)
    .map((word: any) => word.slice(0, 2).toUpperCase());

  return initialsArray.join("");
};

export const capitalize = (full_name: any) => {
  if (full_name) return full_name?.charAt(0).toUpperCase() + full_name.slice(1);
};
