import React, { useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import useCustomQuery from "../../hooks/useCustomQuery";
import { Spinner } from "..";
import { CheckIcon } from "@heroicons/react/24/outline";

export type ServiceTypes = {
  pkid: number;
  service_type: string;
};

type Props = {
  selected: ServiceTypes | null;
  handleSelect: (value: ServiceTypes | null) => void;
  label?: string;
};

export default function ButtonSelect({ label, selected, handleSelect }: Props) {
  const [menuDown, setMenuDown] = useState(false);
  const queryServiceType = useCustomQuery("service-types", `/service-types/`);
  

  const handleMenuToggle = () => {
    setMenuDown((prevState) => !prevState);
    if (!queryServiceType.data) {
      queryServiceType.refetch();
    }
  };

  const isSelected = (type: ServiceTypes) =>
    selected && selected.pkid === type.pkid;

  
  return (
    <div className="relative">
      <div>
        <label className="block mb-2 text-[13px] text-gray-900">{label}</label>
      </div>
      <div
        onClick={handleMenuToggle}
        className="bg-white flex items-center justify-between border border-stroke px-3 py-3.5 rounded-md cursor-pointer"
      >
        <div className="flex items-center space-x-3">
          <p className="text-[13px] text-gray-900">
            {selected && selected ? selected.service_type : "Service Type"}
          </p>
        </div>
        <ChevronDownIcon className={`w-4 h-4 ${menuDown && "rotate-180"}`} />
      </div>
      <div
        className={`border border-stroke w-full rounded-md bg-white shadow-default ${
          menuDown ? "absolute z-999" : "hidden"
        }`}
      >
        {queryServiceType.isLoading ? (
          <div className="flex items-center justify-center h-full w-full">
            <Spinner styles="h-4 w-4 border-2 text-primary_color" />
          </div>
        ) : (
          queryServiceType.data?.data?.map((type: ServiceTypes) => (
            <div
              key={type.pkid}
              onClick={() => {
                handleSelect(type);
                setMenuDown(false);
              }}
              className={`flex items-center cursor-pointer justify-between py-4 text-[13px] ${
                isSelected(type) ? "bg-[#a8b3cf1f] rounded-md" : ""
              } border-b border-b-stroke text-meta-8 relative cursor-default select-none px-4`}
            >
              <p className="text-[13px]">{type.service_type}</p>
              {isSelected(type) && (
                <CheckIcon className="w-4 h-4 text-primary_color" />
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
}
