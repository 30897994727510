import axios, { AxiosRequestConfig } from "axios";
import { getLocalStorageToken } from "../utils/localStorageToken";

export const keyjaniApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const tokenConfig = (): AxiosRequestConfig => {
  const token = getLocalStorageToken();
  const Config: AxiosRequestConfig = {
    headers: {
      "Content-type": "application/json",
    },
  };

  if (token) {
    Config.headers!["Authorization"] = `JWT ${token}`;
  }
  return Config;
};

export default axios;
