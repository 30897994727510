import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ImageState } from "../../../types/image/model";

const initialState: ImageState = {
  image: null,
};

const imageSlice = createSlice({
  name: "image",
  initialState,
  reducers: {
    addImage: (state, { payload }: PayloadAction<string>) => {
      state.image = payload;
    },
    removeImage: (state) => {
      state.image = null;
    },

    updateImage: (state, { payload }: PayloadAction<string>) => {
      state.image = payload;
    },

    clearImageState: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const { addImage, removeImage, updateImage, clearImageState } =
  imageSlice.actions;

export default imageSlice.reducer;
