import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TenantType, TenantTypes } from "../types";
import { RootState } from "../redux/store";
import {
  setCurrentTenant,
  setTenant,
  setTenants,
  updateTenant,
  deleteTenant,
  setTenantToBeEdited,
  setTenantModalDelete,
  setTenantModal,
} from "../redux/features/tenant/tenantSlice";
import useGeolocation from "./useGeolocation";

const initialTenantState: TenantTypes = {
  first_name: "",
  last_name: "",
  phone_number: "",
  email: "",
};

export default function useTenant() {
  const dispatch = useDispatch();
  const { country } = useGeolocation();
  const [errors, setErrors] = React.useState<Record<string, string>>({});
  const [values, setValues] = React.useState<TenantTypes>(initialTenantState);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    let phoneNumber = value;
    if (name === "phone_number" && country?.country_calling_code) {
      if (!value.startsWith(country.country_calling_code)) {
        phoneNumber = `${country.country_calling_code}${value}`;
      }
    }

    setValues({
      ...values,
      [name]: name === "phone_number" ? phoneNumber : value,
    });
  };

  const validateTenant = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const phoneRegex = /^\+\d{3}\d{9}$/;
    let temp: Record<string, string> = {};

    temp.email = emailRegex.test(values.email || "")
      ? ""
      : "Invalid email format";
    temp.phone_number = phoneRegex.test(values.phone_number || "")
      ? ""
      : "Invalid phone number";
    setErrors({ ...temp });
    return Object.values(temp).every((x) => !x);
  };

  const handleClearForm = () => {
    setValues(initialTenantState);
    setErrors({});
  };

  const state = useSelector((state: RootState) => state.tenant);

  const getCurrentTenant = useCallback(
    (tenant: TenantType) => {
      dispatch(setCurrentTenant(tenant));
    },
    [dispatch]
  );

  const getTenants = useCallback(
    (tenants: TenantType[]) => {
      dispatch(setTenants(tenants));
    },
    [dispatch]
  );

  const getTenant = useCallback(
    (tenant: TenantType) => {
      dispatch(setTenant(tenant));
    },
    [dispatch]
  );

  const changeTenant = useCallback(
    (tenant: TenantType) => {
      dispatch(updateTenant(tenant));
    },
    [dispatch]
  );

  const removeTenant = useCallback(
    (tenant: TenantType) => {
      dispatch(deleteTenant(tenant));
    },
    [dispatch]
  );

  const getTenantToBeEdited = useCallback(
    (tenant: string) => {
      dispatch(setTenantToBeEdited(tenant));
    },
    [dispatch]
  );

  const toggleTenantModal = useCallback(
    (toggle: boolean) => {
      dispatch(setTenantModal(toggle));
    },
    [dispatch]
  );

  const toggleTenantModalDelete = useCallback(
    (toggle: boolean) => {
      dispatch(setTenantModalDelete(toggle));
    },
    [dispatch]
  );

  return {
    ...state,
    values,
    errors,
    setValues,
    getTenant,
    getTenants,
    removeTenant,
    changeTenant,
    validateTenant,
    handleClearForm,
    getCurrentTenant,
    toggleTenantModal,
    handleInputChange,
    getTenantToBeEdited,
    toggleTenantModalDelete,
  };
}
