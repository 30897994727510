import React from "react";
import Modal from "./Modal";
import Button from "../global/Button";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/solid";
import useWorkspace from "../../hooks/useWorkspace";
import useCustomQuery from "../../hooks/useCustomQuery";
import Spinner from "../global/Spinner";
import { capitalize } from "../../utils/stringUtils";

type Props = {
  isOpen: boolean;
  close: () => void;
};

export default function WorkspaceSelect({ isOpen, close }: Props) {
  const {
    workspaces,
    currentWorkspace,
    navigate,
    toggleWorkspaceModal,
    getCurrentWorkspace,
  } = useWorkspace();
  const query = useCustomQuery("workspaces", "/workspaces/", true);

  const headContent = (
    <div className="flex flex-row justify-between">
      <h5 className="text-[18px] text-primary_color font-semibold">
        Select/Create a workspace
      </h5>
      <div
        className="hover:bg-itemhover p-1.5 rounded-md cursor-pointer"
        onClick={close}
      >
        <XMarkIcon className="w-5 h-5" />
      </div>
    </div>
  );
  const bodyContent = (
    <div className="w-full">
      <div className="w-full flex flex-col space-y-4">
        {query.isFetching ? (
          <div className="flex items-center h-full justify-center">
            <Spinner styles="h-4 w-4 border-2" />
          </div>
        ) : (
          <>
            {workspaces.length === 0 ? (
              <div className="flex flex-col space-y-3">
                <p className="text-primary_orange text-[15px]">
                  You have no workspaces created yet!, please create/add your
                  workspaces
                </p>
                <div
                  onClick={() => {
                    toggleWorkspaceModal(true);
                    close();
                  }}
                  className="w-full cursor-pointer h-20  border-dashed border-2 border-stroke hover:border-secondary_color rounded-md flex items-center justify-center"
                >
                  <p className="text-md">Create a workspace</p>
                </div>
              </div>
            ) : (
              <div className="w-full flex flex-col space-y-2">
                {workspaces.map((workspace) => (
                  <div
                    key={workspace.pkid}
                    onClick={() => {
                      getCurrentWorkspace(workspace.slug);
                      navigate("/workspaces");
                      close();
                    }}
                    className={`py-6 flex flex-col space-y-4  w-full bg-back1 bg-no-repeat cursor-pointer bg-cover border ${
                      currentWorkspace === workspace.slug
                        ? "border-primary_color"
                        : "border-stroke"
                    } p-4 rounded-md bg-white justify-between`}
                  >
                    <div className="flex items-center justify-center">
                      <p className="text-sm">{capitalize(workspace.name)}</p>
                    </div>
                  </div>
                ))}
                <div
                  onClick={() => {
                    close();
                    toggleWorkspaceModal(true);
                  }}
                  className="py-6 px-20 cursor-pointer w-full border-dashed border-2 border-stroke rounded-md flex items-center space-x-3 justify-center"
                >
                  <PlusIcon className="h-5 w-5" />
                  <p className="text-[15px]">Create a workspace</p>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );

  const footerContent = (
    <div className="flex items-center space-x-6 justify-end px-3">
      <div>
        <Button
          small
          text="Cancel"
          onClick={close}
          buttonStyle="h-10 w-20 border border-itemactive font-bold bg-gradient-to-r rounded-md hover:bg-itemhover"
        />
      </div>
    </div>
  );
  return (
    <Modal
      open={isOpen}
      onClose={close}
      body={bodyContent}
      actions={footerContent}
      title={headContent}
      style={{ width: "100%" }}
    />
  );
}
