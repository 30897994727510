import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { getLocalStorageToken } from "./localStorageToken";
import { useEffect } from "react";

export const AuthenticationRoute = () => {
  const token = getLocalStorageToken();
  const location = useLocation();
  return token !== null && token !== undefined && token !== "undefined" ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export const PublicRoutes = ({ isPublic }: { isPublic: boolean }) => {
  const token = getLocalStorageToken();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const from = location.state?.from?.pathname || "/dashboard";
    if (token) {
      navigate(from, { replace: true });
    }
  }, [token, location.state, navigate]);

  if (token === null || token === undefined || (token === "" && isPublic)) {
    return <Outlet />;
  }
  return <Outlet />;
};
