import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BuildingType } from "../types";
import { RootState } from "../redux/store";
import {
  DeleteBuild,
  setBuilding,
  setBuildingModalDelete,
  setBuildingModalUpdate,
  setBuildings,
  setBuildingToBeEdited,
  setCurrentBuilding,
  UpdateBuilding,
} from "../redux/features/building/buildingSlice";

const initialBuildingState: BuildingType = {
  name: "",
  address: "",
};

export default function useBuilding() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [values, setValues] =
    React.useState<BuildingType>(initialBuildingState);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleClearForm = () => {
    setValues(initialBuildingState);
  };

  const state = useSelector((state: RootState) => state.building);

  const getCurrentBuilding = useCallback(
    (building: BuildingType) => {
      dispatch(setCurrentBuilding(building));
    },
    [dispatch]
  );

  const getBuildings = useCallback(
    (buildings: BuildingType[]) => {
      dispatch(setBuildings(buildings));
    },
    [dispatch]
  );

  const getBuilding = useCallback(
    (building: BuildingType) => {
      dispatch(setBuilding(building));
    },
    [dispatch]
  );

  const updateBuilding = useCallback(
    (building: BuildingType) => {
      dispatch(UpdateBuilding(building));
    },
    [dispatch]
  );

  const deleteBuilding = useCallback(
    (building: BuildingType) => {
      dispatch(DeleteBuild(building));
    },
    [dispatch]
  );

  const getBuildingToBeEdited = useCallback(
    (building: string) => {
      dispatch(setBuildingToBeEdited(building));
    },
    [dispatch]
  );

  const toggleBuildingModalUpdate = useCallback(
    (toggle: boolean) => {
      dispatch(setBuildingModalUpdate(toggle));
    },
    [dispatch]
  );

  const toggleBuildingModalDelete = useCallback(
    (toggle: boolean) => {
      dispatch(setBuildingModalDelete(toggle));
    },
    [dispatch]
  );

  return {
    ...state,
    values,
    navigate,
    dispatch,
    setValues,
    handleInputChange,
    handleClearForm,
    getBuilding,
    getBuildings,
    deleteBuilding,
    updateBuilding,
    getCurrentBuilding,
    getBuildingToBeEdited,
    toggleBuildingModalDelete,
    toggleBuildingModalUpdate,
  };
}
