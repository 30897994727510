import React, { useEffect, useRef, useState } from "react";

import {
  EllipsisHorizontalIcon,
  TrashIcon,
  UserIcon,
} from "@heroicons/react/24/outline";

type Props = {
  onClick: () => void;
  onModal: () => void;
};

const TableMenu = ({ onClick, onModal }: Props) => {
  const trigger = useRef(null);
  const dropdown = useRef(null);
  const [onOpen, setOnOpen] = useState(false);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent): void => {
      if (!dropdown.current) return;
      if (
        !onOpen ||
        (dropdown.current as HTMLElement).contains(target as Node) ||
        (trigger.current &&
          (trigger.current as HTMLElement).contains(target as Node))
      )
        return;
      setOnOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  }, [onOpen]);

  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent): void => {
      if (!onOpen || keyCode !== 27) return;
      setOnOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  }, [onOpen]);

  return (
    <>
      <div
        ref={trigger}
        onClick={() => {
          setOnOpen(!onOpen);
        }}
        className="border border-stroke rounded-md h-6 w-6 flex items-center justify-center hover:bg-itemhover transition ease-out duration-100"
      >
        <EllipsisHorizontalIcon className="h-5 w-5" />
      </div>
      <div
        ref={dropdown}
        onFocus={() => setOnOpen(true)}
        onBlur={() => setOnOpen(false)}
        className={`absolute right-72 p-2 mt-2 z-999 flex w-62.5 bg-back2 bg-cover bg-no-repeat min-w-[16px] min-h-[16px] cursor-pointer flex-col transform transition-all duration-[198ms] backdrop-blur-xl rounded-lg border border-stroke bg-white/90 shadow-default ${
          onOpen === true
            ? "visible transform scale-100  ease-in duration-100 opacity-100"
            : "invisible transform scale-95  ease-out duration-100 opacity-0"
        }`}
      >
        <div className="w-full flex flex-col space-y-1">
          <div
            onClick={onModal}
            className="w-full hover:bg-background_color_second flex items-center space-x-3  px-3 py-1 text-sm rounded-md font-medium duration-300 ease-in-out lg:text-base"
          >
            <UserIcon className="h-4 w-4" />
            <p className=" text-gray_color text-[13px]">Assign Role</p>
          </div>
          <hr className="border-stroke" />
          <div
            onClick={onClick}
            className="hover:bg-background_color_second flex items-center space-x-3  px-3 py-1 rounded-md text-sm font-medium duration-300 ease-in-out lg:text-base"
          >
            <TrashIcon className="h-4 w-4 text-red-400" />
            <p className="text-red-400 text-[13px]">Remove Member</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TableMenu;
