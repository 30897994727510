import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ServiceState, ServiceType } from "../../../types";

const initialState: ServiceState = {
  services: [],
  serviceToBeEdited: null,
  currentService: null,
  openServiceModal: false,
  openServiceModalDelete: false,
};

const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    setServices: (state, { payload }: PayloadAction<ServiceType[]>) => {
      if (payload) {
        state.services = payload;
      }
    },
    setCurrentService: (state, { payload }: PayloadAction<ServiceType>) => {
      if (payload) {
        state.currentService = payload;
      }
    },
    setService: (state, { payload }: PayloadAction<ServiceType>) => {
      if (payload) {
        state.services = [...state.services, payload];
      }
    },
    UpdateService: (state, { payload }: PayloadAction<ServiceType>) => {
      if (payload) {
        state.services = state.services.map((service) =>
          service.pkid === payload.pkid ? payload : service
        );
      }
    },
    DeleteService: (state, { payload }: PayloadAction<ServiceType>) => {
      if (payload) {
        state.services = state.services.filter(
          (service) => service.pkid !== payload.pkid
        );
      }
    },
    setServiceToBeEdited: (state, { payload }: PayloadAction<string>) => {
      if (payload) {
        state.serviceToBeEdited = payload;
      }
    },
    setServiceModal: (state, { payload }: PayloadAction<boolean>) => {
      state.openServiceModal = payload;
    },
    setServiceModalDelete: (state, { payload }: PayloadAction<boolean>) => {
      state.openServiceModalDelete = payload;
    },
  },
});

export const {
  setService,
  setServices,
  UpdateService,
  DeleteService,
  setServiceModal,
  setCurrentService,
  setServiceToBeEdited,
  setServiceModalDelete,
} = serviceSlice.actions;

export default serviceSlice.reducer;
