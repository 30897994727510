import React, { ChangeEvent, useState } from "react";

type Props = {
  label?: string;
  name?: string;
  placeholder?: string;
  value?: string;
  styles?: string;
  rows?: number;
  onChange?: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onFocus?: VoidFunction;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

export default function TextArea({
  label,
  name,
  placeholder,
  value,
  onChange,
  styles,
  rows,
  onFocus,
}: Props) {
  const [focus, setFocus] = useState(false);

  const handleFocus = () => {
    if (onFocus) {
      onFocus();
    }
    setFocus(true);
  };

  const handleBlur = () => {
    setFocus(false);
  };

  return (
    <div
      className={`h-full flex flex-col ${styles} ${
        focus
          ? "border-black ring-black"
          : "border-stroke ring-primary_color"
      }`}
    >
      <textarea
        className="h-full flex-1 w-full rounded border text-sm border-none outline-none bg-transparent px-3 py-[0.32rem]"
        rows={rows}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        name={name}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </div>
  );
}
