import { useCallback } from "react";
import { RootState } from "../redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
  addImage,
  clearImageState,
  removeImage,
  updateImage,
} from "../redux/features/image/imageSlice";

export default function useImage() {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state.image);

  const selectImage = useCallback(
    (image: string) => {
      dispatch(addImage(image));
    },
    [dispatch]
  );

  const unSelectImage = useCallback(() => {
    dispatch(removeImage());
  }, [dispatch]);

  const clearImages = useCallback(() => {
    dispatch(clearImageState());
  }, [dispatch]);

  const handleImage = async (e: any) => {
    const { name, files } = e.target;

    const handleImageDispatch = async (actionType: any) => {
      if (files && files[0]) {
        const image = files[0];
        dispatch(actionType(image));
      }
    };

    if (name === "createImage") {
      await handleImageDispatch(addImage);
    }
    if (name === "updateImage") {
      await handleImageDispatch(updateImage);
    }
  };

  return {
    ...state,
    selectImage,
    unSelectImage,
    clearImages,
    handleImage,
  };
}
