import React, { useState } from "react";
import { TrashIcon, UserIcon } from "@heroicons/react/24/outline";
import Spinner from "./Spinner";
import DeleteInvitation from "../modals/DeleteInvitation";
import { Table, Group, Text, ActionIcon, LoadingOverlay } from "@mantine/core";
import { useLoadingData } from "../../hooks/useLoadingData";
import { columns } from "../../utils/data";
import { WorkspaceType } from "../../types";

interface InvitationsTableProps {
  data: any[];
  isLoading: boolean;
  error: any;
  workspace?: WorkspaceType;
}

export default function InvitationsTable({
  data,
  isLoading,
  workspace,
}: InvitationsTableProps) {
  const [modal, setModal] = useState<boolean>(false);
  const [currentInvitation, setCurrentInvitation] = useState<number | null>(
    null
  );
  const { visible } = useLoadingData(data, isLoading);

  const rows = data.map((item) => (
    <Table.Tr key={item.pkid}>
      <Table.Td>
        <Group gap="sm">
          <div className="h-11 w-11 flex items-center justify-center bg-itemactive rounded-full">
            <UserIcon className="h-5 w-4" />
          </div>
          <div>
            <Text fz="sm" fw={500}>
              {item.name}
            </Text>
            <Text fz="xs" c="dimmed">
              {item.received_by}
            </Text>
          </div>
        </Group>
      </Table.Td>
      <Table.Td>{item.role}</Table.Td>
      <Table.Td>
        <Text
          fz="sm"
          fw={500}
          className="bg-[#FEF2B3] w-[70%] h-6 flex items-center justify-center rounded-md px-5"
        >
          {item.invitation_status}
        </Text>
      </Table.Td>
      <Table.Td>
        <Group gap={0} justify="flex-start">
          <ActionIcon
            variant="subtle"
            color="gray"
            onClick={() => {
              setModal(true);
              setCurrentInvitation(item);
            }}
            className="hover:bg-red-100 flex items-center justify-center space-x-3  h-8 w-8 border border-red-600 rounded-md text-sm font-medium duration-300 ease-in-out lg:text-base"
          >
            <TrashIcon className="h-4 w-4 text-red-600" />
          </ActionIcon>
        </Group>
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <>
      <Table.ScrollContainer
        minWidth={700}
        className="overflow-hidden relative border border-stroke rounded-md shadow-lg px-4 pt-4 pb-0"
      >
        <LoadingOverlay
          visible={visible}
          loaderProps={{
            children: <Spinner styles="h-4 w-4 border-2 text-primary_color" />,
          }}
        />
        <Table verticalSpacing="sm">
          <Table.Thead>
            <Table.Tr>
              {columns.map((item) => (
                <Table.Th key={item.id}>{item.label}</Table.Th>
              ))}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </Table.ScrollContainer>

      <DeleteInvitation
        isOpen={modal}
        Close={() => setModal(false)}
        workspace={workspace && workspace}
        item={currentInvitation}
      />
    </>
  );
}
