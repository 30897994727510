import React, { useState } from "react";
import Modal from "./Modal";
import { Button, CheckBox, InsideInput, Spinner } from "..";
import { XMarkIcon } from "@heroicons/react/24/solid";
import useWorkspace from "../../hooks/useWorkspace";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { keyjaniApi, tokenConfig } from "../../api";
import { AxiosResponse } from "axios";
import { WorkspaceType } from "../../types";
import { useAlert } from "../../hooks/useAlert";
import { ResponseStatus, Screens } from "../../types/enums";

type Props = {
  isOpen: boolean;
  Close: () => void;
  workspace?: WorkspaceType;
};

export default function InviteModal({ isOpen, Close, workspace }: Props) {
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();
  const [sortBy, setSortBy] = useState<string>("");
  const {
    errors,
    handleClearForm,
    invitationValues,
    handleInputChange,
    validateWorkspaceInvitation,
  } = useWorkspace();

  const mutation = useMutation({
    mutationFn: () =>
      keyjaniApi.post(
        `/workspaces/${workspace?.slug}/invitation/`,
        {
          ...invitationValues,
          role: sortBy,
          workspace: workspace?.pkid,
        },
        tokenConfig()
      ),
    onSuccess: (res: AxiosResponse) => {
      if (res.data && res.data) {
        queryClient.invalidateQueries({ queryKey: ["invitations"] });
        showAlert({
          message: "Workspace Invitation sent successfully.",
          type: ResponseStatus.SUCCESS,
          component: Screens.workspace,
        });
        Close();
        handleClearForm();
        setSortBy("");
      }
    },
    onError: (error: any) => {
      if (error.response && error.response.data) {
        if (error.response.data.received_by) {
          showAlert({
            error: error.response.data.received_by[0],
            type: ResponseStatus.FAILED,
            component: Screens.workspace,
          });
        }
        if (error.response.data.error) {
          showAlert({
            error: "workspace invitation with this received by already exists.",
            type: ResponseStatus.FAILED,
            component: Screens.workspace,
          });
        }
        handleClearForm();
        Close();
      }
    },
  });

  const handleSubmit = (e?: React.FormEvent<HTMLButtonElement>) => {
    e?.preventDefault();
    if (validateWorkspaceInvitation() && sortBy) {
      mutation.mutate();
    } else {
      showAlert({
        error: "The email of the recipient and his/her role is required.",
        type: ResponseStatus.INFO,
        component: Screens.workspace,
      });
    }
  };

  const handleSortChange = (value: string) => {
    setSortBy(value);
  };

  const headContent = (
    <div className="flex flex-row justify-between">
      <p className="text-[1.25rem] font-medium ">
        Invite a member(s) to my workspace
      </p>
      <div
        className="hover:bg-itemhover p-1.5 rounded-md cursor-pointer"
        onClick={Close}
      >
        <XMarkIcon className="w-5 h-5" />
      </div>
    </div>
  );
  const bodyContent = (
    <div className="w-full">
      <div className="flex flex-col space-y-10">
        <div className="flex flex-col space-y-2">
          <p className="text-[0.875rem] font-medium">
            Available Member Invites: 1 or more
          </p>
          <p className="text-[0.875rem] text-gray_color">
            Your workspace members will get an email that gives them access to
            your workspace.
          </p>
        </div>
        <p></p>
        <div className="flex flex-col space-y-5">
          <InsideInput
            label="Email addresses*"
            placeholder="name@gmail.com, name@gmail.com"
            type="email"
            value={invitationValues.received_by}
            name="received_by"
            onChange={handleInputChange}
            styles="border rounded-md py-4"
            error={errors?.email}
          />
          <div className="flex flex-col space-y-5">
            <p className="text-[0.875rem] font-medium">Role Levels*</p>
            <CheckBox
              checked={sortBy === "Owner"}
              disabled
              onChange={() => handleSortChange("Owner")}
              right={
                <div className="flex flex-col">
                  <label className="text-[0.855rem] font-medium">
                    Workspace Owner
                  </label>
                  <p className="text-[0.855rem]  text-grey_black">
                    Full access to team dashboard (list of all workspace, tenant
                    creation restriction, team members management). Can access
                    specific workspace of which they are a member.
                  </p>
                </div>
              }
              type="radio"
            />
            <CheckBox
              checked={sortBy === "Admin"}
              onChange={() => handleSortChange("Admin")}
              right={
                <div className="flex flex-col">
                  <label className="text-[0.855rem] font-medium">Admin</label>
                  <p className="text-[0.855rem]  text-grey_black">
                    Can view, create, edit and access specific workspace of
                    which they are a member.
                  </p>
                </div>
              }
              type="radio"
            />
            <CheckBox
              checked={sortBy === "Editor"}
              onChange={() => handleSortChange("Editor")}
              right={
                <div className="flex flex-col">
                  <label className="text-[0.855rem] font-medium">Editor</label>
                  <p className="text-[0.855rem]  text-grey_black">
                    Can view and access specific workspaces of which they are a
                    member.
                  </p>
                </div>
              }
              type="radio"
            />
          </div>
        </div>
      </div>
    </div>
  );

  const footerContent = (
    <div className="w-full flex items-center justify-end space-x-3">
      <div>
        <Button
          small
          text="Cancel"
          onClick={Close}
          buttonStyle="h-10 w-20 border border-itemactive font-bold bg-gradient-to-r rounded-md hover:bg-itemhover"
        />
      </div>
      <div>
        <Button
          small
          text={
            mutation.isPending ? <Spinner styles="h-4 w-4 border-2" /> : "Send"
          }
          onClick={() => handleSubmit()}
          buttonStyle="h-10 w-20 font-bold  bg-gradient-to-r rounded-md text-white bg-findit_red"
        />
      </div>
    </div>
  );

  return (
    <Modal
      open={isOpen}
      onClose={Close}
      body={bodyContent}
      actions={footerContent}
      title={headContent}
    />
  );
}
