import { Link } from "react-router-dom";
import Button from "../global/Button";
import InviteModal from "../modals/InviteModal";
import { useState } from "react";
import { MenuSelectBuilding } from "..";
import { WorkspaceType } from "../../types";

const Breadcrumb = ({
  pageName,
  subtitle,
  workspace,
  invite,
  isMenu,
}: {
  pageName: string;
  subtitle?: string;
  workspace?: WorkspaceType;
  invite?: boolean;
  isMenu?: boolean;
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  return (
    <>
      <div className="mb-6 flex flex-col gap-3 sm:flex-row sm:items-center sm:justify-between">
        <div className="flex flex-col space-y-1">
          <div className="flex item-center justify-center space-x-3">
            <h2 className="text-[2rem] font-semibold text-black dark:text-white">
              {pageName}
            </h2>
            {isMenu && <MenuSelectBuilding />}
          </div>
        </div>

        {invite && workspace ? (
          <Button
            onClick={() => setModalOpen(true)}
            text="Invite a member"
            small
            buttonStyle="shadow-lg border border-stroke bg-primary_color text-white hover:text-black hover:border-primary_color hover:bg-background_color_second px-1 sm:px-3 rounded-md h-10 flex items-center justify-center"
          />
        ) : (
          <nav>
            <ol className="flex items-center gap-2">
              <li className="text-sm">
                <Link to="/">Dashboard /</Link>
              </li>
              <li className="font-medium text-sm">{pageName}</li>
            </ol>
          </nav>
        )}
      </div>
      <InviteModal
        isOpen={modalOpen}
        Close={() => setModalOpen(false)}
        workspace={workspace}
      />
    </>
  );
};

export default Breadcrumb;
