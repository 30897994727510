import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PropertyState, PropertyType } from "../../../types";

const initialState: PropertyState = {
  properties: [],
  propertyToBeEdited: null,
  currentProperty: null,
};

const propertySlice = createSlice({
  name: "property",
  initialState,
  reducers: {
    setProperties: (state, { payload }: PayloadAction<PropertyType[]>) => {
      if (payload) {
        state.properties = payload;
      }
    },
    setCurrentProperty: (state, { payload }: PayloadAction<PropertyType>) => {
      if (payload) {
        state.currentProperty = payload;
      }
    },
    setProperty: (state, { payload }: PayloadAction<PropertyType>) => {
      if (payload) {
        state.properties = [...state.properties, payload];
      }
    },
    UpdateProperty: (state, { payload }: PayloadAction<PropertyType>) => {
      if (payload) {
        state.properties = state.properties.map((property: PropertyType) =>
          property.pkid === payload.pkid ? payload : property
        );
      }
    },
    DeleteProperty: (state, { payload }: PayloadAction<PropertyType>) => {
      if (payload) {
        state.properties = state.properties.filter(
          (property: PropertyType) => property.pkid !== payload.pkid
        );
      }
    },
    setPropertyToBeEdited: (state, { payload }: PayloadAction<string>) => {
      if (payload) {
        state.propertyToBeEdited = payload;
      }
    },
  },
});

export const {
  setProperty,
  setProperties,
  UpdateProperty,
  DeleteProperty,
  setCurrentProperty,
  setPropertyToBeEdited,
} = propertySlice.actions;

export default propertySlice.reducer;
