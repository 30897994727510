import { lazy, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { Supensing } from "./components";
import { AuthenticationRoute, PublicRoutes } from "./utils/AuthenticationRoute";
import { getLocalStorageToken } from "./utils/localStorageToken";
import useCustomQuery from "./hooks/useCustomQuery";

const WorkSpacePage = lazy(() => import("./pages/app/WorkSpace"));
const WorkSpaceDetailsPage = lazy(() => import("./pages/app/WorkSpaceDetails"));
const UnitPage = lazy(() => import("./pages/app/Units"));
const TenantPage = lazy(() => import("./pages/app/Tenants"));
const UnitDetailPage = lazy(() => import("./pages/app/UnitDetail"));
const ServicePage = lazy(() => import("./pages/app/Services"));
const ServiceDetailPage = lazy(() => import("./pages/app/ServiceDetail"));
const BuildingPage = lazy(() => import("./pages/app/Buildings"));
const BuildingDetailPage = lazy(() => import("./pages/app/BuildingDetail"));
const DashboardPage = lazy(() => import("./pages/app/Dashboard"));
const LoginPage = lazy(() => import("./pages/auth/Login"));
const RegisterPage = lazy(() => import("./pages/auth/Register"));
const ForgotPasswordPage = lazy(() => import("./pages/auth/ForgotPassword"));
const ResetPasswordPage = lazy(() => import("./pages/auth/ResetPassword"));
const WorkspaceInvitationPage = lazy(
  () => import("./pages/app/AcceptInvitation")
);
const ProfilePage = lazy(() => import("./pages/auth/Profile"));
const EmailVerificationPage = lazy(
  () => import("./pages/auth/EmailVerification")
);

function App() {
  window.addEventListener("error", (event) => {
    event.preventDefault();
  });
  const token = getLocalStorageToken();
  const query = useCustomQuery("user", "/auth/profile/me/");

  useEffect(() => {
    if (token) {
      query.refetch();
    }
  }, [token]);

  return (
    <Routes>
      <Route element={<AuthenticationRoute />}>
        <Route index element={<Supensing Component={DashboardPage} />} />
        <Route
          path="/dashboard"
          element={<Supensing Component={DashboardPage} />}
        />
        <Route
          path="/workspaces"
          element={<Supensing Component={WorkSpacePage} />}
        />
        <Route
          path="/workspace-detail/:slug"
          element={<Supensing Component={WorkSpaceDetailsPage} />}
        />
        <Route
          path="/buildings"
          element={<Supensing Component={BuildingPage} />}
        />
        <Route
          path="/building-details"
          element={<Supensing Component={BuildingDetailPage} />}
        />
        <Route path="/units" element={<Supensing Component={UnitPage} />} />
        <Route
          path="/unit-details"
          element={<Supensing Component={UnitDetailPage} />}
        />

        <Route
          path="/services"
          element={<Supensing Component={ServicePage} />}
        />
        <Route
          path="/service-details"
          element={<Supensing Component={ServiceDetailPage} />}
        />
        <Route path="/tenants" element={<Supensing Component={TenantPage} />} />
        <Route
          path="/profile"
          element={<Supensing Component={ProfilePage} />}
        />
      </Route>

      <Route element={<PublicRoutes isPublic={true} />}>
        <Route
          index
          path="/login"
          element={<Supensing Component={LoginPage} />}
        />
        <Route
          path="/register"
          element={<Supensing Component={RegisterPage} />}
        />
        <Route
          path="/forgot-password"
          element={<Supensing Component={ForgotPasswordPage} />}
        />
        <Route
          path="/activate/:uid/:token"
          element={<Supensing Component={EmailVerificationPage} />}
        />
        <Route
          path="/reset-password/:uid/:token"
          element={<Supensing Component={ResetPasswordPage} />}
        />
        <Route
          path="/invitation/:uid/:token"
          element={<Supensing Component={WorkspaceInvitationPage} />}
        />
      </Route>
    </Routes>
  );
}

export default App;
