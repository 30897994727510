import React from "react";
type Props = {
  type?: string;
  name?: string;
  placeholder?: string;
  icon?: React.ReactElement;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  iconRight?: React.ReactElement | boolean;
  styles?: string;
};

export default function SearchInput({
  type,
  name,
  placeholder,
  value,
  onChange,
  icon,
  styles,
  iconRight,
}: Props) {
  return (
    <div className={`flex flex-col justify-center p-2 ${styles}`}>
      <div className="flex items-center flex-row space-x-4">
        <div>{icon && icon}</div>
        <input
          type={type}
          name={name}
          value={value}
          className={`outline-none text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white`}
          placeholder={placeholder}
          onChange={onChange}
        />
        <div>{iconRight && iconRight}</div>
      </div>
    </div>
  );
}
