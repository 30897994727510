import React from "react";
import {
  Menu,
  Table,
  Group,
  Text,
  ActionIcon,
  Anchor,
  LoadingOverlay,
} from "@mantine/core";

import {
  BuildingOffice2Icon,
  EllipsisVerticalIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
  TrashIcon,
  PencilIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import SearchInput from "./SearchInput";
import moment from "moment";
import Spinner from "./Spinner";
import { useLoadingData } from "../../hooks/useLoadingData";
import { buildingColumns } from "../../utils/data";
import useBuilding from "../../hooks/useBuilding";
import useService from "../../hooks/useService";
import useUnit from "../../hooks/useUnit";
import { BuildingTableProps } from "../../types";

export default function BuildingTable({
  data,
  isLoading,
  navigate,
}: BuildingTableProps) {
  const { toggleUnitModal } = useUnit();
  const { toggleServiceModal } = useService();
  const { toggleBuildingModalDelete, getCurrentBuilding } = useBuilding();
  const { sortedData, visible, search, handleSearchChange, handleReset } =
    useLoadingData(data, isLoading);
  const rows = sortedData?.map((item) => (
    <Table.Tr key={item.name}>
      <Table.Td>
        <Group gap="sm">
          <BuildingOffice2Icon className="h-5 w-4" />
          <Text fz="sm" fw={500}>
            {item.name}
          </Text>
        </Group>
      </Table.Td>
      <Table.Td>
        <Anchor component="button" size="sm">
          {item.address}
        </Anchor>
      </Table.Td>
      <Table.Td>
        <Group gap={0} justify="flex-start">
          <Menu
            transitionProps={{ transition: "pop" }}
            withArrow
            position="bottom-end"
            withinPortal
          >
            <Menu.Target>
              <ActionIcon variant="subtle" color="gray">
                <EllipsisVerticalIcon className="h-5 w-5" />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown className="flex w-62.5 bg-back2 bg-cover bg-no-repeat min-w-[16px] min-h-[16px] cursor-pointer flex-col transform transition-all duration-[198ms] backdrop-blur-xl rounded-lg border border-stroke bg-white/90 shadow-default">
              <Menu.Item
                onClick={() => {
                  getCurrentBuilding(item);
                  navigate && navigate(`/building-details`);
                }}
                leftSection={<PencilIcon className="h-4 w-4 text-green-400" />}
                className="w-full hover:bg-background_color_second flex items-center space-x-3  px-3 py-1 text-sm rounded-md font-medium duration-300 ease-in-out lg:text-base"
              >
                <p className=" text-green-400 text-[13px]">View Details</p>
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  getCurrentBuilding(item);
                  toggleBuildingModalDelete(true);
                }}
                leftSection={<TrashIcon className="h-4 w-4 text-red-400" />}
                className="hover:bg-background_color_second flex items-center space-x-3  px-3 py-1 rounded-md text-sm font-medium duration-300 ease-in-out lg:text-base"
              >
                <p className="text-red-400 text-[13px]">Delete building</p>
              </Menu.Item>

              <Menu.Item
                onClick={() => {
                  navigate && navigate("/services");
                  getCurrentBuilding(item);
                  toggleServiceModal(true);
                }}
                leftSection={<PlusIcon className="h-4 w-4 text-blue-400" />}
                className="hover:bg-background_color_second flex items-center space-x-3  px-3 py-1 rounded-md text-sm font-medium duration-300 ease-in-out lg:text-base"
              >
                <p className="text-blue-400 text-[13px]">Add Service</p>
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  navigate && navigate("/units");
                  getCurrentBuilding(item);
                  toggleUnitModal(true);
                }}
                leftSection={<PlusIcon className="h-4 w-4 text-blue-400" />}
                className="hover:bg-background_color_second flex items-center space-x-3  px-3 py-1 rounded-md text-sm font-medium duration-300 ease-in-out lg:text-base"
              >
                <p className="text-blue-400 text-[13px]">Add Unit</p>
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <div className="flex flex-col space-y-3">
      <div className="flex items-center justify-between space-x-3">
        <div>
          <h2 className="text-[20px] font-semibold text-primary_color dark:text-white">
            Buildings
          </h2>
        </div>
        <div className="flex items-center space-x-4">
          <SearchInput
            styles="border border-stroke bg-white rounded-3xl focus:border-blue-500"
            placeholder="Search by buildings...."
            onChange={handleSearchChange}
            name="search"
            icon={<MagnifyingGlassIcon className="w-4 h-4" />}
            value={search}
            iconRight={
              search.length > 0 && (
                <div
                  className="h-5 w-5 flex items-center rounded-full justify-center hover:bg-stroke cursor-pointer"
                  onClick={handleReset}
                >
                  <XMarkIcon className="w-3 h-3" />
                </div>
              )
            }
          />
        </div>
      </div>
      <Table.ScrollContainer
        minWidth={700}
        className="overflow-hidden relative border border-stroke rounded-md shadow-lg px-4 pt-4 pb-0"
      >
        <LoadingOverlay
          visible={visible}
          loaderProps={{
            children: <Spinner styles="h-4 w-4 border-2 text-primary_color" />,
          }}
        />
        <Table verticalSpacing="md">
          <Table.Thead>
            <Table.Tr>
              {buildingColumns.map((item) => (
                <Table.Th key={item.id}>{item.label}</Table.Th>
              ))}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </Table.ScrollContainer>
      <div>
        <p className="text-[14.5px] sm:text-[15px] font-medium">
          {sortedData.length === 0
            ? "No buildings"
            : sortedData.length === 1
            ? "1 building"
            : `${sortedData.length} buildings`}
        </p>
      </div>
    </div>
  );
}
