import React from "react";

export default function Form({
  children,
  className,
  onSubmit,
  autoComplete,
  enctype,
  ...other
}: {
  children: React.ReactNode;
  className?: string;
  autoComplete?: string;
  enctype?: string;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
}) {
  return (
    <form
      encType={enctype}
      {...other}
      className={className}
      onSubmit={onSubmit}
      autoComplete={autoComplete}
    >
      {children}
    </form>
  );
}
