import React, { useState } from "react";
import SearchInput from "./SearchInput";
import {
  EllipsisHorizontalIcon,
  MagnifyingGlassIcon,
  TrashIcon,
  UserIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import Spinner from "./Spinner";
import DeleteMember from "../modals/DeleteMember";
import UpdateModal from "../modals/UpdateModal";
import {
  Table,
  Group,
  Text,
  ActionIcon,
  Menu,
  LoadingOverlay,
} from "@mantine/core";
import { useLoadingData } from "../../hooks/useLoadingData";
import { memberColumns } from "../../utils/data";
import { MembersTableProps } from "../../types";

export default function MembersTable({
  data,
  isLoading,
  workspace,
}: MembersTableProps) {
  const [modal, setModal] = useState<boolean>(false);
  const [modalUpdate, setModalUpdate] = useState<boolean>(false);
  const [currentMember, setCurrentMember] = useState<number | null>(null);

  const { sortedData, visible, search, handleSearchChange, handleReset } =
    useLoadingData(data, isLoading);

  const rows = sortedData.map((item) => (
    <Table.Tr key={item.pkid}>
      <Table.Td>
        <Group gap="sm">
          <div className="h-11 w-11 flex items-center justify-center bg-itemactive rounded-full">
            <UserIcon className="h-5 w-4" />
          </div>
          <div>
            <Text fz="sm" fw={500}>
              {item.name}
            </Text>
            <Text fz="xs" c="dimmed">
              {item.received_by}
            </Text>
          </div>
        </Group>
      </Table.Td>
      <Table.Td>{item.role}</Table.Td>
      {item.role === "Owner" ? (
        <Table.Td></Table.Td>
      ) : (
        <Table.Td>
          <Group gap={0} justify="flex-start">
            <Menu
              transitionProps={{ transition: "pop" }}
              withArrow
              position="bottom-end"
              withinPortal
            >
              <Menu.Target>
                <ActionIcon variant="subtle" color="gray">
                  <EllipsisHorizontalIcon className="h-5 w-5" />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown className="flex w-62.5 bg-back2 bg-cover bg-no-repeat min-w-[16px] min-h-[16px] cursor-pointer flex-col transform transition-all duration-[198ms] backdrop-blur-xl rounded-lg border border-stroke bg-white/90 shadow-default">
                <Menu.Item
                  onClick={() => {
                    setModalUpdate(true);
                    setCurrentMember(item);
                  }}
                  leftSection={<UserIcon className="h-4 w-4" />}
                  className="w-full hover:bg-background_color_second flex items-center space-x-3  px-3 py-1 text-sm rounded-md font-medium duration-300 ease-in-out lg:text-base"
                >
                  <p className=" text-gray_color text-[13px]">Assign Role</p>
                </Menu.Item>
                <Menu.Item
                  onClick={() => {
                    setModal(true);
                    setCurrentMember(item);
                  }}
                  leftSection={<TrashIcon className="h-4 w-4 text-red-400" />}
                  className="hover:bg-background_color_second flex items-center space-x-3  px-3 py-1 rounded-md text-sm font-medium duration-300 ease-in-out lg:text-base"
                >
                  <p className="text-red-400 text-[13px]">Remove Member</p>
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Table.Td>
      )}
    </Table.Tr>
  ));

  return (
    <div className="flex flex-col space-y-3">
      <div className="flex items-center justify-end space-x-3">
        <SearchInput
          styles="border border-stroke bg-white rounded-3xl focus:border-blue-500"
          placeholder="Search by any field"
          onChange={handleSearchChange}
          name="search"
          icon={<MagnifyingGlassIcon className="w-4 h-4" />}
          value={search}
          iconRight={
            search.length > 0 && (
              <div
                className="h-5 w-5 flex items-center rounded-full justify-center hover:bg-stroke cursor-pointer"
                onClick={handleReset}
              >
                <XMarkIcon className="w-3 h-3" />
              </div>
            )
          }
        />
      </div>

      <Table.ScrollContainer
        minWidth={700}
        className="overflow-hidden relative border border-stroke rounded-md shadow-lg px-4 pt-4 pb-0"
      >
        <LoadingOverlay
          visible={visible}
          loaderProps={{
            children: <Spinner styles="h-4 w-4 border-2 text-primary_color" />,
          }}
        />
        <Table verticalSpacing="sm">
          <Table.Thead>
            <Table.Tr>
              {memberColumns.map((item) => (
                <Table.Th key={item.id}>{item.label}</Table.Th>
              ))}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </Table.ScrollContainer>
      <div>
        <p className="text-[14.5px] sm:text-[15px] font-medium">
          {sortedData.length === 0
            ? "No members"
            : sortedData.length === 1
            ? "1 member"
            : `${sortedData.length} members`}
        </p>
      </div>

      <DeleteMember
        isOpen={modal}
        Close={() => setModal(false)}
        workspace={workspace && workspace}
        item={currentMember}
      />
      <UpdateModal
        isOpen={modalUpdate}
        Close={() => setModalUpdate(false)}
        workspace={workspace && workspace}
        item={currentMember}
      />
    </div>
  );
}
