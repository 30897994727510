import { createSlice } from "@reduxjs/toolkit";
import {
  getLocalStorageToken,
  removeLocalStorageToken,
} from "../../../utils/localStorageToken";
import { AuthState } from "../../../types";

const initialState: AuthState = {
  token: getLocalStorageToken(),
  userInfo: null,
  isAuthenticated: false,
  user_loading: false,
  google_login_loading: false,
  facebook_login_loading: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      removeLocalStorageToken();
      Object.assign(state, initialState);
    },
    setUserData: (state, { payload }) => {
      if (payload !== null) {
        state.userInfo = payload;
      }
    },
    setIsAuthenticated: (state, { payload }) => {
      state.isAuthenticated = payload;
    },
  },
});

export const { logout, setUserData, setIsAuthenticated } = authSlice.actions;

export default authSlice.reducer;
