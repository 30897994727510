import {
  ExclamationCircleIcon,
  InformationCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { ResponseStatus } from "../../types/enums";
import { useAlert } from "../../hooks/useAlert";

const CustomAlert = ({ outlined = false }: { outlined?: boolean }) => {
  const { message, type, isVisible, error, hideAlert } = useAlert();
  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setIsRendered(true);
    } else {
      const timer = setTimeout(() => setIsRendered(false), 300);
      return () => clearTimeout(timer);
    }
  }, [isVisible]);

  if (!isRendered) return null;

  const getAlertClass = () => {
    return twMerge(
      "fixed z-9999 bottom-7 right-4 py-3 px-5 rounded-md flex items-center space-x-3 transition-transform duration-300 ease-linear transform",
      isVisible ? "translate-x-0" : "translate-x-full",
      type === ResponseStatus.SUCCESS
        ? twMerge(
            "text-white",
            outlined ? "border border-green-900" : "bg-green-300"
          )
        : type === ResponseStatus.FAILED
        ? twMerge(
            "text-white",
            outlined ? "border border-red-900" : "bg-red-300"
          )
        : twMerge(
            "text-white",
            outlined ? "border border-blue-900" : "bg-blue-300"
          ),
      "bg-black/60 shadow-2xl drop-shadow-md"
    );
  };

  const handleClear = () => {
    hideAlert();
  };

  const displayIcon = () => {
    if (type === ResponseStatus.SUCCESS) {
      return <InformationCircleIcon className="w-6 h-6 text-text_green/60" />;
    } else if (type === ResponseStatus.FAILED) {
      return <ExclamationCircleIcon className="w-6 h-6 text-text_red/60" />;
    } else {
      return <ExclamationCircleIcon className="w-6 h-6 text-text_blue/60" />;
    }
  };

  const displayColor = () => {
    if (type === ResponseStatus.SUCCESS) {
      return "text-text_green/60";
    } else if (type === ResponseStatus.FAILED) {
      return "text-text_red/60";
    } else {
      return "text-text_blue/60";
    }
  };

  return (
    <div className={getAlertClass()}>
      <div className="">{displayIcon()}</div>
      {message && <p className={`text-sm ${displayColor()}`}>{message}</p>}
      {error && <p className={`text-sm ${displayColor()}`}>{error}</p>}
      <div
        onClick={handleClear}
        className="hover:bg-itemhover/30 p-1 rounded-full cursor-pointer"
      >
        <XMarkIcon className={`w-4 h-4 ${displayColor()}`} />
      </div>
    </div>
  );
};

export default CustomAlert;
