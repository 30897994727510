import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";

export default function Modal({
  open,
  title,
  body,
  actions,
  onClose,
  style,
}: {
  open: boolean;
  title: React.ReactNode;
  body: React.ReactNode;
  actions: React.ReactNode;
  onClose: () => void;
  style?: Object;
}) {
  const [showModal, setShowModal] = useState<boolean>(open);

  useEffect(() => {
    setShowModal(open);
  }, [open]);

  const handleClose = useCallback(() => {
    setShowModal(false);
    setTimeout(() => {
      onClose();
    }, 300);
  }, [onClose]);

  return (
    <React.Fragment>
      <Dialog style={style} open={showModal} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{body}</DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
