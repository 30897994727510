import { useState, useEffect } from "react";
import { useDisclosure } from "@mantine/hooks";

interface RowData {
  [key: string]: any;
}

export function useLoadingData<T extends RowData>(
  data: T[],
  isLoading: boolean
) {
  const [search, setSearch] = useState("");
  const [sortedData, setSortedData] = useState<T[]>([]);
  const [visible, { toggle }] = useDisclosure(false);

  useEffect(() => {
    if (isLoading) {
      toggle();
    } else {
      setSortedData(data);
      if (visible) toggle();
    }
  }, [isLoading, data]);

  function filterData(data: T[], search: string): T[] {
    const query = search.toLowerCase().trim();
    return data.filter((item) =>
      Object.keys(item).some((key) => {
        const value = item[key as keyof T];
        return typeof value === "string" && value.toLowerCase().includes(query);
      })
    );
  }

  function sortData(data: T[], search: string): T[] {
    return filterData(data, search);
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearch(value);
    setSortedData(sortData(data, value));
  };

  const handleReset = () => {
    setSearch("");
    setSortedData(data);
  };

  return { sortedData, visible, search, handleSearchChange, handleReset };
}
