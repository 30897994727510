import React, { useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { CheckIcon } from "@heroicons/react/24/outline";
import { UnitType } from "../../types";
import { capitalize } from "../../utils/stringUtils";
import useUnit from "../../hooks/useUnit";

export default function MenuSelectUnit() {
  const [menuDown, setMenuDown] = useState<boolean>(false);
  const { units, currentUnit, getCurrentUnit } = useUnit();

  const toggle = () => {
    setMenuDown((prevState) => !prevState);
  };

  const handleSelectedUnit = (unit: UnitType) => {
    if (unit) {
      getCurrentUnit(unit);
      setMenuDown(false);
    }
  };

  return (
    <div className="relative mt-1">
      <div
        onClick={toggle}
        className="bg-white flex items-center space-x-3 border border-stroke px-3 py-2 rounded-md cursor-pointer"
      >
        <div className="flex items-center space-x-3">
          <p className="text-[13px] text-gray-900">
            {currentUnit ? (
              <span className="font-bold text-black/50">
                {capitalize(currentUnit?.slug)}
              </span>
            ) : (
              "Select Unit"
            )}
          </p>
        </div>
        <ChevronDownIcon
          className={`w-4 h-4 text-black/50 ${menuDown && "rotate-180"}`}
        />
      </div>
      <div
        className={`border border-stroke w-full rounded-md bg-white shadow-default ${
          menuDown ? "absolute z-999" : "hidden"
        }`}
      >
        {units?.map((unit: UnitType) => (
          <div
            key={unit?.pkid}
            onClick={() => handleSelectedUnit(unit)}
            className={`flex items-center justify-between py-4 text-[13px] border-b border-b-stroke text-meta-8 relative cursor-pointer select-none px-4`}
          >
            <p className="text-[13px]">{unit.number}</p>
            {currentUnit?.pkid === unit?.pkid && (
              <CheckIcon className="w-3 h-3 text-primary_color" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
